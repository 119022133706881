import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby";
import playButton from '../images/play-button.png'

export default class Sidebar extends React.Component {
  render() {
    return (
      <article className="sidebar">
        <div className="sidebar-video">
          <Link to={this.props.url}><img src={this.props.thumbnail} alt={this.props.title} className="video-thumbnail" /></Link>
          <Link to={this.props.url}><img src={playButton} className="play-overlay" alt="" /></Link>
        </div>
        <h3><Link to={this.props.url}>{this.props.title}</Link></h3>
        <p><Link to={this.props.url}>{this.props.description}</Link></p>          
      </article>
    )
  }
}

Sidebar.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired
}