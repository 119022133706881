import React from "react"
import PropTypes from "prop-types"

export default class VideoEmbed extends React.Component {
  render() {
    let aspect = (this.props.useCinema ? 'embed-responsive embed-responsive-241by100' : 'embed-responsive embed-responsive-16by9');
    let shortCaption = (this.props.author || this.props.location ? '' : 'short');
    return (
      <div className="video-embed">
        <div className={aspect}>
          <iframe title={this.props.title} src={"https://player.vimeo.com/video/" + this.props.vimeoId} width="854" height="480" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
        </div>
        <div className={"row video-caption justify-content-center " + shortCaption}>
          <div className="col-11">
            <h2>{this.props.title}</h2>
            {(this.props.author || this.props.location) && <div className="container author-location"><div className="row">
            {this.props.author && <div className="col-6 col-sm-4">
                <i className="fas fa-user mr-1"></i> By <em>{this.props.author}</em>
              </div>}
              {this.props.location && <div className="col-6 col-sm-4">
                <i className="fas fa-map-marker-alt mr-1"></i> In <em>{this.props.location}</em>
              </div>}
            </div></div>}
          </div>        
        </div>
      </div>
    )
  }
}


VideoEmbed.propTypes = {
  title: PropTypes.string,
  vimeoId: PropTypes.string,
  useCinema: PropTypes.bool,
  author: PropTypes.string,
  location: PropTypes.string
}
