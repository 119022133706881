import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import VideoEmbed from "../../components/video-embed"
import { Link } from "gatsby"
import Sidebar from "../../components/sidebar"

export default class FromAshesToACrownOfBeauty extends React.Component {
  render() {
    return (
      <Layout title="From Ashes to a Crown of Beauty" sectionName="Stories" sectionPath="/stories">
        <Seo title="From Ashes to a Crown of Beauty"/>
        <div className="container">
          <div className="row">
            <section className="col-sm-9">
              <VideoEmbed vimeoId="231564831" title="From Ashes to a Crown of Beauty" useCinema={true} author="Mary" location="Alaska" />

              <p>"You just told my story." That's what I hear night after night when I share my hope story with Native American women. For years, I believed that I was the only girl to be sexually abused; especially by someone who was supposed to be family. But, 1 out of 3 Native American women report being sexually assaulted during their lifetime. This is what led me to finally express my pain out loud, instead of stuffing it down with drugs and alcohol. There was a lot of shame that even led to thoughts of suicide.</p>

              <p className="story-callout">"God has been faithful to His Word in turning my ashes to a crown of beauty."</p>

              <p>Too many young women end up living with feelings of shame and despair. But, my story is different; it has hope and healing. I learned how to share that hope and healing by attending a conference called Warrior Leadership Summit. This was made possible because of Hope for Native America gifts.</p>

              <p>If more women like me can hear and see that someone has lived their shame, pain, and utter loneliness, they'd see that there is hope. For the first time, these girls realize there may be a way to finally feel safe. God has been faithful to His Word in turning my ashes to a crown of beauty. He is using those old ashes to bring hope to Native America.</p>

              <p>You can also be hope for Native America for many girls, just like me, by finding a way to get involved when you click the button below.</p>

              <p className="mt-5 text-center">
                <Link className="btn btn-secondary" to="/get-involved/equip-a-leader">Equip A Leader</Link>
              </p>

            </section>
            <aside className="col-sm-3">
              <h2><span>Here's how <strong>you</strong> can</span><br/><em>get involved!</em></h2>
              <Sidebar title="Equip a Leader" 
                      description={`Equip young Native Christian leaders to reach their communities for Jesus and to be a living example of hope to broken young people.`}
                      url="/get-involved/equip-a-leader"
                      thumbnail="/video-thumbnails/equip-a-leader.jpg" />
            </aside>
          </div>
        </div>
      </Layout>
    )
  }
}
